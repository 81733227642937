.Candidate-Card {
  display: block;
}

.Candidate-Card-Info {
  background-color: #282c34;
  color: white;
  padding: 5px;
  border-style: double;
  border-radius: 10px;
}

@media(hover: hover) and (pointer: fine) {
  .Candidate-Card:hover {
    background-color: #989696;
    cursor: pointer;
  }
}