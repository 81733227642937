.Footer {
  text-align: center;
  background-color: #282c34;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
  width: 100%;
  position: fixed;
  bottom: 0px;
}

.Footer-Link {
  display: inline;
}